import { Editor, Frame } from '@craftjs/core';
import { Element } from '@craftjs/core';
import { decode } from 'js-base64';
import { useLocation, useParams } from 'react-router-dom';

import {
  LayoutSingleColumn,
  MobileBottomNavigation,
  NamedLink,
  NamedRedirect,
  Page,
  PrimaryButton,
} from '../../components';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { Button } from './components/Button';
import { Container } from './components/Container';
import { HeroSection } from './components/HeroSection';
import { LandingSection } from './components/LandingSection';
import { ListingsSection } from './components/ListingsSection';
import { ShowcaseCarousel } from './components/ShowcaseCarousel';
import { ShowcaseCarouselItem } from './components/ShowcaseCarouselItem';
import { Spacer } from './components/Spacer';
import { Text } from './components/Text';
import {
  useCreateEditablePage,
  useEditablePagesQueryParams,
  useGetEditablePage,
  useGetEditablePages,
} from './EditablePages.hooks';
import { useIsNoldUser } from './EditablePages.hooks';
import css from './EditablePages.module.css';
import { Toolbox, useEnableLinksState } from './Toolbox';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { cn } from 'util/cn';

const EditablePages = () => {
  const { pageId = '' } = useParams<{ pageId: string }>();
  const isNoldEmail = useIsNoldUser();

  if (pageId === 'list' && isNoldEmail) {
    return <ListEditablePages />;
  }

  return <EditPage pageId={pageId} />;
};

const EditPage = ({ pageId }: { pageId: string }) => {
  const scrollingDisabled = useIsScrollingDisabled();
  const location = useLocation();
  const isNoldEmail = useIsNoldUser();
  const isEditing = !!location.search?.includes('edit=true') && isNoldEmail;

  const [{ version }] = useEditablePagesQueryParams();
  const { data: page, isLoading, isError, error } = useGetEditablePage(pageId, version);

  const [enableLinks] = useEnableLinksState();

  if (!pageId) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (isError && !isEditing) {
    return <NamedRedirect name="LandingPage" />;
  }

  if (isError && isEditing) {
    return (
      <div className="text-red-500 w-screen h-screen p-2 grid place-content-center text-center">
        Error: {JSON.stringify(error)}
      </div>
    );
  }

  if (!page) {
    return <NamedRedirect name="LandingPage" />;
  }

  return (
    <Page title="THE NOLD" scrollingDisabled={scrollingDisabled}>
      <Editor
        resolver={{
          HeroSection,
          Container,
          Spacer,
          Text,
          Button,
          LandingSection,
          ShowcaseCarousel,
          ShowcaseCarouselItem,
          ListingsSection,
        }}
      >
        <div className="grid grid-cols-[1fr_auto]">
          <LayoutSingleColumn
            topbar={<TopbarContainer />}
            footer={<MobileBottomNavigation />}
            className="max-h-screen overflow-y-auto"
            mainColumnClassName={cn({ [css.linksDisabled]: isEditing && !enableLinks })}
          >
            <Frame json={isEditing ? undefined : decode(page?.content || '')}>
              <Element canvas is="div" id="root">
                <Element
                  canvas
                  is={HeroSection}
                  imgSrc="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_compressed.webp"
                  imgBlurSrc="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_blur.webp"
                  imgAlt="Hero Image"
                >
                  <Element is={Text} color="white" weight="bold" font="syne" size="5">
                    PIFERI EXQUISITE SAMPLE SALE
                  </Element>
                  <Element
                    is={Text}
                    color="white"
                    weight="bold"
                    font="syne"
                    casing="uppercase"
                    size="1"
                  >
                    Live now
                  </Element>
                  <Element is={Container} gap="2" canvas orientation="row">
                    <Element
                      is={Button}
                      variant="primary"
                      link={{ name: 'SearchPage', to: { search: `?pageId=${pageId}` } }}
                      text="Primary"
                    />
                    <Element is={Button} text="Default" />
                  </Element>
                </Element>
                <Element is={Spacer} size="8" />
                <Element is={ShowcaseCarousel} canvas>
                  <Element
                    is={ShowcaseCarouselItem}
                    imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    canvas
                  >
                    <Element is={Text} font="syne" size="3" casing="uppercase" color="white">
                      Showcase Item 1
                    </Element>
                    <Element is={Spacer} size="2" />
                    <Element is={Text} color="white">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Element>
                  </Element>
                  <Element
                    is={ShowcaseCarouselItem}
                    imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    canvas
                  >
                    <Element is={Text} font="syne" size="3" casing="uppercase" color="white">
                      Showcase Item 2
                    </Element>
                    <Element is={Spacer} size="2" />
                    <Element is={Button} text="Test" />
                  </Element>
                  <Element
                    is={ShowcaseCarouselItem}
                    imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    canvas
                  >
                    <Element is={Text} font="syne" size="3" casing="uppercase" color="white">
                      Showcase Item 3
                    </Element>
                    <Element is={Spacer} size="2" />
                    <Element is={Text} color="white">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Element>
                  </Element>
                  <Element
                    is={ShowcaseCarouselItem}
                    imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    canvas
                  >
                    <Element is={Text} font="syne" size="3" casing="uppercase" color="white">
                      Showcase Item 3
                    </Element>
                    <Element is={Spacer} size="2" />
                    <Element is={Text} color="white">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Element>
                  </Element>
                </Element>
                <Element is={ListingsSection} />
              </Element>
            </Frame>
            <FooterContainer />
          </LayoutSingleColumn>
          {isEditing && <Toolbox page={page} />}
        </div>
      </Editor>
    </Page>
  );
};

const ListEditablePages = () => {
  const scrollingDisabled = useIsScrollingDisabled();
  const { data: editablePages } = useGetEditablePages();
  const createEditablePage = useCreateEditablePage();

  return (
    <Page title="THE NOLD | Editable Pages" scrollingDisabled={scrollingDisabled}>
      <div className="grid grid-cols-1 gap-2">
        <h2 className="p-2 font-bold font-syne text-3">THE NOLD | Editable Pages</h2>
        <form
          className="flex flex-col gap-2 p-2 items-start bg-gray-100"
          onSubmit={e => {
            e.preventDefault();
            const path = e.currentTarget.pageRoute.value;
            const name = e.currentTarget.pageName.value;
            if (path && name) {
              createEditablePage.mutate(
                { path, name },
                {
                  onSuccess: () => {
                    e.currentTarget.reset();
                  },
                }
              );
            }
          }}
        >
          <h1 className="font-bold uppercase font-syne text-2">Create new page</h1>
          <div className="flex gap-2">
            <label>
              Page route (path)
              <input className="p-1 bg-white" placeholder="page-route" id="pageRoute" />
            </label>
            <label>
              Page name
              <input className="p-1 bg-white" placeholder="Page Name" id="pageName" />
            </label>
          </div>
          <PrimaryButton type="submit" disabled={createEditablePage.isLoading}>
            {createEditablePage.isLoading ? 'Creating...' : 'Create'}
          </PrimaryButton>
        </form>
        <div className="grid grid-cols-1 gap-1 p-2">
          <h1 className="font-bold uppercase font-syne text-2 mb-1">Pages list</h1>
          {editablePages?.map(page => (
            <NamedLink
              key={page.id}
              name="EditablePages"
              params={{ pageId: page.name }}
              to={{ search: `?edit=true` }}
              className="p-2 bg-gray-100 hover:bg-gray-200 transition-colors font-bold"
            >
              ({page.id}) {page.name}
            </NamedLink>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default EditablePages;
