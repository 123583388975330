import { useNode } from '@craftjs/core';

export const ContainerSettings = () => {
  const {
    actions: { setProp },
    orientation,
    gap,
  } = useNode(node => ({
    orientation: node.data.props.orientation,
    gap: node.data.props.gap,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Container settings</div>

      <label>
        Orientation
        <select
          value={orientation}
          onChange={e => setProp((props: any) => (props.orientation = e.target.value))}
        >
          <option value="row">Row</option>
          <option value="column">Column</option>
        </select>
      </label>

      <label>
        Gap
        <select value={gap} onChange={e => setProp((props: any) => (props.gap = e.target.value))}>
          <option value="0">0</option>
          <option value="px">px</option>
          <option value=".25">.25</option>
          <option value=".5">.5</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </label>
    </div>
  );
};
