import { useNode } from '@craftjs/core';

const spacingOptions = [
  '0',
  'px',
  'quarter',
  'half',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
] as const;

export type SpacingOptions = (typeof spacingOptions)[number];

export const SpacerSettings = () => {
  const {
    actions: { setProp },
    size,
  } = useNode(node => ({
    size: node.data.props.size,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Spacer settings</div>

      <label>
        Size
        <select value={size} onChange={e => setProp((props: any) => (props.size = e.target.value))}>
          {spacingOptions.map(value => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};
