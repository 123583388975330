import { useNode } from '@craftjs/core';
import React from 'react';

export const ShowcaseCarouselItemSettings = () => {
  const {
    actions: { setProp },
    imgSrc,
    imgBlurSrc,
    imgAlt,
  } = useNode(node => ({
    imgSrc: node.data.props.imgSrc,
    imgBlurSrc: node.data.props.imgBlurSrc,
    imgAlt: node.data.props.imgAlt,
  }));

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-lg font-semibold">Showcase Carousel Item Settings</h3>

      <div className="flex flex-col gap-2">
        <label className="flex flex-col">
          Image Source
          <input
            type="text"
            value={imgSrc}
            onChange={e => setProp((props: any) => (props.imgSrc = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>

        <label className="flex flex-col">
          Blur Image Source
          <input
            type="text"
            value={imgBlurSrc}
            onChange={e => setProp((props: any) => (props.imgBlurSrc = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>

        <label className="flex flex-col">
          Image Alt Text
          <input
            type="text"
            value={imgAlt}
            onChange={e => setProp((props: any) => (props.imgAlt = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>
      </div>
    </div>
  );
};
