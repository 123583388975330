import { useNode } from '@craftjs/core';

export const TextSettings = () => {
  const {
    actions: { setProp },
    font,
    children,
    casing,
    weight,
    as,
    size,
    color,
    customColor,
    responsiveMinScreen,
    responsiveMaxScreen,
    lineHeight,
    width,
  } = useNode(node => ({
    font: node.data.props.font,
    children: node.data.props.children,
    casing: node.data.props.casing,
    weight: node.data.props.weight,
    as: node.data.props.as,
    size: node.data.props.size,
    color: node.data.props.color,
    customColor: node.data.props.customColor,
    responsiveMinScreen: node.data.props.responsiveMinScreen,
    responsiveMaxScreen: node.data.props.responsiveMaxScreen,
    lineHeight: node.data.props.lineHeight,
    width: node.data.props.width || 'full',
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Text settings</div>

      <label>
        Text
        <input
          type="text"
          value={children}
          onChange={e => setProp((props: any) => (props.children = e.target.value))}
        />
      </label>

      <label>
        Component (for SEO)
        <select value={as} onChange={e => setProp((props: any) => (props.as = e.target.value))}>
          <option value="span">span</option>
          <option value="p">p</option>
          <option value="h1">h1</option>
          <option value="h2">h2</option>
          <option value="h3">h3</option>
          <option value="h4">h4</option>
          <option value="h5">h5</option>
          <option value="h6">h6</option>
        </select>
      </label>

      {/* Size */}
      <label>
        Size
        <select value={size} onChange={e => setProp((props: any) => (props.size = e.target.value))}>
          <option value="0000">text-0000</option>
          <option value="000">text-000</option>
          <option value="00">text-00</option>
          <option value="0-in-px">text-0-in-px</option>
          <option value="0">text-0</option>
          <option value="1">text-1</option>
          <option value="2">text-2</option>
          <option value="3">text-3</option>
          <option value="4">text-4</option>
          <option value="5">text-5</option>
          <option value="6">text-6</option>
          <option value="7">text-7</option>
        </select>
      </label>

      <label>
        Font
        <select value={font} onChange={e => setProp((props: any) => (props.font = e.target.value))}>
          <option value="montserrat">Montserrat</option>
          <option value="syne">Syne</option>
        </select>
      </label>

      <label>
        Case
        <select
          value={casing}
          onChange={e => setProp((props: any) => (props.casing = e.target.value))}
        >
          <option value="default">Default</option>
          <option value="uppercase">Uppercase</option>
          <option value="lowercase">Lowercase</option>
          <option value="capitalize">Capitalize</option>
        </select>
      </label>

      <label>
        Weight
        <select
          value={weight}
          onChange={e => setProp((props: any) => (props.weight = e.target.value))}
        >
          <option value="default">Default</option>
          <option value="extralight">Extra Light</option>
          <option value="light">Light</option>
          <option value="normal">Normal</option>
          <option value="medium">Medium</option>
          <option value="semibold">Semi Bold</option>
          <option value="bold">Bold</option>
          <option value="extrabold">Extra Bold</option>
          <option value="black">Black</option>
        </select>
      </label>

      <label>
        Line Height
        <select
          value={lineHeight}
          onChange={e => setProp((props: any) => (props.lineHeight = e.target.value))}
        >
          <option value="none">None</option>
          <option value="tight">Tight</option>
          <option value="snug">Snug</option>
          <option value="normal">Normal</option>
          <option value="relaxed">Relaxed</option>
          <option value="loose">Loose</option>
        </select>
      </label>

      <label>
        Color
        <select
          value={color}
          onChange={e => setProp((props: any) => (props.color = e.target.value))}
        >
          <option value="black">Black</option>
          <option value="white">White</option>
          <option value="custom">Custom</option>
        </select>
      </label>

      {color === 'custom' && (
        <label>
          Custom color
          <input
            type="text"
            value={customColor}
            onChange={e => setProp((props: any) => (props.customColor = e.target.value))}
            placeholder="Enter custom color"
          />
        </label>
      )}

      <label>
        Responsive Min Screen
        <input
          type="text"
          value={responsiveMinScreen}
          onChange={e => setProp((props: any) => (props.responsiveMinScreen = e.target.value))}
          placeholder="0px (default)"
        />
        <small className="max-w-[300px] inline-block mt-1">
          Enter a pixel value (e.g., 768px) or a breakpoint name (xs, sm, md, lg, xl). The component
          will be hidden below this screen size. Default: '0px' (always visible).
        </small>
      </label>

      <label>
        Responsive Max Screen
        <input
          type="text"
          value={responsiveMaxScreen}
          onChange={e => setProp((props: any) => (props.responsiveMaxScreen = e.target.value))}
          placeholder="Infinity (default)"
        />
        <small className="max-w-[300px] inline-block mt-1">
          Enter a pixel value (e.g., 1024px), 'Infinity', or a breakpoint name (xs, sm, md, lg, xl).
          The component will be hidden above this screen size. Default: 'Infinity' (always visible).
        </small>
      </label>

      <label className="grid gap-.5">
        Width
        <select
          value={width === 'full' ? 'full' : 'custom'}
          onChange={e => {
            const value = e.target.value;
            if (value === 'full') {
              setProp((props: any) => (props.width = value));
            } else if (value === 'custom') {
              setProp((props: any) => (props.width = 200)); // Default custom width
            }
          }}
          className="bg-white"
        >
          <option value="full">Full width</option>
          <option value="custom">Custom width</option>
        </select>
        {width !== 'full' && (
          <input
            type="number"
            value={width}
            onChange={e => setProp((props: any) => (props.width = Number(e.target.value)))}
            placeholder="Custom width in pixels"
            className="bg-white mt-1"
          />
        )}
      </label>
    </div>
  );
};
