import { useNode, UserComponent } from '@craftjs/core';
import { PropsWithChildren } from 'react';

import { HeroSectionSettings } from './HeroSectionSettings';
import { ImageLazy } from 'components';

type HeroSectionProps = PropsWithChildren<{
  imgSrc: string;
  imgBlurSrc: string;
  imgAlt?: string;
}>;

export const HeroSection: UserComponent<HeroSectionProps> = ({
  children,
  imgSrc,
  imgBlurSrc,
  imgAlt = 'Hero Image',
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      ref={ref => ref && connect(drag(ref))}
      className="relative isolate overflow-hidden grid content-end w-full h-[680px] max-h-[min(680px,70vh)]"
    >
      <ImageLazy
        src={imgSrc}
        blurSrc={imgBlurSrc}
        alt={imgAlt}
        className="absolute inset-0 w-full h-full object-cover object-[50%_100%]"
      />
      <div className="relative z-10 grid gap-2 w-full max-w-[var(--n-content-max-width)] mx-auto px-2 py-10 md:px-10">
        <div className="absolute z-[-1] inset-0 -inset-x-[100vw] w-[1000vw] bg-gradient-to-t from-black/20 via-black/30 to-transparent" />
        {children}
      </div>
    </div>
  );
};

HeroSection.craft = {
  displayName: 'HeroSection',
  related: {
    settings: HeroSectionSettings,
  },
};
