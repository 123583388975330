import { useNode, UserComponent } from '@craftjs/core';
import React from 'react';

import { LandingSection } from './LandingSection';
import { ShowcaseCarouselSettings } from './ShowcaseCarouselSettings';

type ShowcaseCarouselProps = {
  children: React.ReactNode;
  title?: string;
  center?: boolean;
};

export const ShowcaseCarousel: UserComponent<ShowcaseCarouselProps> = ({
  children,
  title = 'Showcase Carousel',
  center = true,
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <LandingSection title={title} center={center} ref={ref => ref && connect(drag(ref))}>
      {children}
    </LandingSection>
  );
};

ShowcaseCarousel.craft = {
  displayName: 'ShowcaseCarousel',
  related: {
    settings: ShowcaseCarouselSettings,
  },
};
