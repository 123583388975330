import { useNode } from '@craftjs/core';
import React from 'react';

import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';

export const ListingsSectionSettings = () => {
  const {
    actions: { setProp },
    title,
    center,
    listingsQueryParams,
  } = useNode(node => ({
    title: node.data.props.title,
    center: node.data.props.center,
    listingsQueryParams: node.data.props.listingsQueryParams,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Listings Section Settings</div>

      <label>
        Title
        <input
          type="text"
          value={title}
          onChange={e => setProp((props: any) => (props.title = e.target.value))}
        />
      </label>
      <Checkbox
        label="Centered if screen is wider than content"
        checked={center}
        onCheckedChange={checked => setProp((props: any) => (props.center = checked))}
      />
      <label>
        Listings Query Params
        <input
          type="text"
          value={listingsQueryParams}
          onChange={e => setProp((props: any) => (props.listingsQueryParams = e.target.value))}
          placeholder="e.g., ?category=shoes&color=red"
        />
      </label>
    </div>
  );
};
