import { useNode, UserComponent } from '@craftjs/core';
import { useMemo } from 'react';

import { LandingSection } from './LandingSection';
import { ListingsSectionSettings } from './ListingsSectionSettings';
import { ListingCard } from 'components';
import {
  FILTERS_DEFAULTS,
  filtersQueryParamsSchema,
} from 'containers/SearchPage/SearchPage.filters';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { parse } from 'util/urlHelpers';

type ListingsSectionProps = {
  title?: string;
  center?: boolean;
  listingsQueryParams?: string;
};

export const ListingsSection: UserComponent<ListingsSectionProps> = ({
  title = 'Listings',
  center = true,
  listingsQueryParams = '',
}) => {
  const params = useMemo(() => {
    const urlQueryParams = parse(listingsQueryParams);
    return filtersQueryParamsSchema.safeParse({
      ...FILTERS_DEFAULTS,
      ...urlQueryParams,
    });
  }, [listingsQueryParams]);

  const { data: listingsData } = useGetListings(params.success ? params.data : {});

  const latestListings = useMemo(() => {
    if (!listingsData?.pages) return [];
    return listingsData.pages.flatMap(page => page.listings).slice(0, 24);
  }, [listingsData]);

  const {
    connectors: { connect, drag },
  } = useNode();

  if (!params.success) {
    return (
      <div className="flex flex-col gap-2 bg-red-100 p-2" ref={ref => ref && connect(drag(ref))}>
        <div className="text-red-500 font-bold">Listing section: invalid params</div>
        <pre>{JSON.stringify(params.error, null, 2)}</pre>
      </div>
    );
  }

  return (
    <LandingSection
      title={title}
      childrenContainerClassName="max-w-full px-3 gap-3"
      center={center}
      ref={ref => ref && connect(drag(ref))}
    >
      {latestListings.map(l => (
        <ListingCard key={l.id.uuid} className="w-[15vw] min-w-[max(250px,16vw)]" listing={l} />
      ))}
    </LandingSection>
  );
};

ListingsSection.craft = {
  displayName: 'ListingsSection',
  related: {
    settings: ListingsSectionSettings,
  },
};
