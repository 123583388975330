import { useNode, UserComponent } from '@craftjs/core';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { ButtonSettings } from './ButtonSettings';
import {
  Button as ButtonComponent,
  NamedLink,
  PrimaryButton as PrimaryButtonComponent,
} from 'components';
import { RouteName } from 'routing/routeConfiguration';

type P = {
  variant?: 'default' | 'primary';
  link?: {
    name: RouteName;
    to?: {
      search: string;
    };
  };
  linkPath?: string;
  text?: string;
  width?: 'fit' | 'full' | number;
};

export const Button: UserComponent<P> = ({
  variant,
  link,
  linkPath,
  text = 'Button',
  width = 'fit',
  ...props
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  const Comp = variant === 'primary' ? PrimaryButtonComponent : ButtonComponent;

  let LinkComp: React.ComponentType<PropsWithChildren<any>> = React.Fragment;

  if (linkPath) {
    LinkComp = ({ children }) => (
      <Link
        to={linkPath}
        className={width === 'full' ? 'w-full' : 'w-fit'}
        ref={ref => ref && connect(drag(ref))}
      >
        {children}
      </Link>
    );
  } else if (link) {
    LinkComp = ({ children }) => (
      <NamedLink
        {...link}
        className={width === 'full' ? 'w-full' : 'w-fit'}
        ref={ref => ref && connect(drag(ref))}
      >
        {children}
      </NamedLink>
    );
  }

  return (
    <LinkComp>
      <Comp
        {...props}
        className={width === 'full' ? 'w-full' : width === 'fit' ? 'w-fit' : ''}
        style={typeof width === 'number' ? { width: `${width}px` } : {}}
        ref={ref => !link && !linkPath && ref && connect(drag(ref as any))}
      >
        {text}
      </Comp>
    </LinkComp>
  );
};

Button.craft = {
  displayName: 'Button',
  related: {
    settings: ButtonSettings,
  },
};
