export const breakpoints = {
  xs: '368px',
  sm: '550px',
  md: '768px',
  lg: '1024px',
  // TODO: These are legacy. Very ugly, yes. Should be removed.
  'lg-paddings': '1128px',
  'lg-md': '1440px',
  xl: '1921px',
} as const;

export type Breakpoint = keyof typeof breakpoints;
