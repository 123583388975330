import { useNode } from '@craftjs/core';

import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';

export const ShowcaseCarouselSettings = () => {
  const {
    actions: { setProp },
    title,
    center,
  } = useNode(node => ({
    title: node.data.props.title,
    center: node.data.props.center,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Showcase Carousel Settings</div>

      <label>
        Title
        <input
          type="text"
          value={title}
          onChange={e => setProp((props: any) => (props.title = e.target.value))}
        />
      </label>
      <Checkbox
        label="Centered if screen is wider than content"
        checked={center}
        onCheckedChange={checked => setProp((props: any) => (props.center = checked))}
      />
    </div>
  );
};
